import React from 'react';
import { Select } from 'react-aria-components';

interface DateDropDownProps {
  onChange: (date: Date) => void;
  value?: Date;
}

const DateDropDown: React.FC<DateDropDownProps> = ({ onChange, value = new Date() }) => {
  // Generate last 5 calendar days
  const dates = [...Array(5)].map((_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - i);
    return date;
  });

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
  };

  // Normalize dates to midnight for comparison
  const normalizeDate = (date: Date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized.getTime();
  };

  // Find matching date from options
  const selectedDate = dates.find((date) => normalizeDate(date) === normalizeDate(value)) || dates[0];

  return (
    <select
      className="select select-bordered w-full max-w-xs"
      value={selectedDate.toISOString()}
      onChange={(e) => onChange(new Date(e.target.value))}
    >
      {dates.map((date) => (
        <option key={date.toISOString()} value={date.toISOString()}>
          {formatDate(date)}
        </option>
      ))}
    </select>
  );
};

export default DateDropDown;
