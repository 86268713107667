import React, { useEffect } from 'react';
import { TimeValue } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { SearchSpotResult } from '../../api/spot-client';
import { createSurfSession } from '../../api/surf-sessions-client';
import SpotSearch from '../../components/SpotSearch';
import TimeSelect from '../../components/TimeSelect';
import DateDropDown from '../../components/common/DateDropDown';

const NewSurfSession = () => {
  const [spot, setSpot] = React.useState<SearchSpotResult | null>(null);
  const [startTime, setStartTime] = React.useState<TimeValue | null>(null);
  const [endTime, setEndTime] = React.useState<TimeValue | null>(null);
  const [sessionStart, setSessionStart] = React.useState<number | null>(null);
  const [sessionEnd, setSessionEnd] = React.useState<number | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [error, setError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const validateTimes = () => {
    if (sessionStart && sessionEnd && sessionEnd <= sessionStart) {
      setError('End time must be after start time');
    } else {
      setError(null);
    }
  };

  const timeValueToTimestamp = (timeValue: TimeValue) => {
    if (timeValue == null) return null;

    const date = new Date(selectedDate);
    date.setHours(timeValue.hour || 0);
    date.setMinutes(timeValue.minute || 0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime() / 1000;
  };

  useEffect(() => {
    if (startTime) setSessionStart(timeValueToTimestamp(startTime));
    if (endTime) setSessionEnd(timeValueToTimestamp(endTime));
  }, [startTime, endTime, selectedDate]);

  useEffect(validateTimes, [sessionStart, sessionEnd]);

  const submit = async () => {
    if (!spot || !sessionStart || !sessionEnd) {
      throw new Error('Missing required fields');
    }

    const surfSession = await createSurfSession({
      spot: {
        name: spot.name,
        surfline_id: spot.id,
      },
      surf_session: {
        session_start: sessionStart,
        session_end: sessionEnd,
      },
    });

    navigate(`/surf_sessions/${surfSession.id}`);
  };

  return (
    <div className="text-xl flex flex-col items-center justify-center w-4/5 max-w-md mx-auto mt-40 space-y-5">
      <div className="flex items-center gap-3">
        <div className="font-semibold whitespace-nowrap">log my surf on</div>
        <DateDropDown value={selectedDate} onChange={setSelectedDate} />
        <div className="font-semibold">at</div>
      </div>
      <span className="w-full font-normal">
        <SpotSearch callback={setSpot} />
      </span>
      <div className="flex space-x-5">
        <p>from</p>
        <TimeSelect aria-label="session-start" callback={setStartTime} />
        <p>to</p>
        <TimeSelect aria-label="session-end" callback={setEndTime} />
      </div>
      <div>{error && <div className="font-normal text-sm text-error">{error}</div>}</div>
      <div>
        {spot && sessionStart && sessionEnd && !error && (
          <button className="btn btn-primary px-8 text-xl" onClick={submit}>
            Go
          </button>
        )}
      </div>
    </div>
  );
};

export default NewSurfSession;
